<template>
  <BaseLayout>
    <div class="container mx-auto p-6">
      <h2 class="text-4xl font-semibold mb-8 text-center">Frisør Dashboard</h2>
      <p class="text-2xl text-center mb-8">Velkommen, {{ salonName }}</p>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <!-- Today's Bookings -->
        <div class="bg-white bg-opacity-50 backdrop-filter backdrop-blur-3xl p-6 rounded-3xl shadow-2xl text-gray-900 col-span-1">
          <h3 class="text-2xl font-semibold mb-4">Dagens Bookinger</h3>
          <div v-if="loading">Indlæser...</div>
          <div v-else>
            <div v-if="todayBookings.length === 0">Ingen bookinger i dag</div>
            <ul class="space-y-4">
              <li v-for="booking in todayBookings" :key="booking.id" class="border-b border-gray-300 pb-4">
                <p class="text-lg font-semibold">{{ booking.name }}</p>
                <p class="text-gray-700">{{ booking.service }}</p>
                <p class="text-gray-500">{{ booking.time }}</p>
                <p class="text-gray-500 italic">{{ booking.notes }}</p> <!-- Display the notes here -->
                <div class="flex space-x-2">
                  <button @click="approveBooking(booking)" class="bg-green-500 text-white px-4 py-2 rounded-full">Godkend</button>
                  <button @click="deleteBooking(booking.id, booking)" class="bg-red-500 text-white px-4 py-2 rounded-full">Slet</button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Upcoming Bookings -->
        <div class="bg-white bg-opacity-50 backdrop-filter backdrop-blur-3xl p-6 rounded-3xl shadow-2xl text-gray-900 col-span-1">
          <h3 class="text-2xl font-semibold mb-4">Kommende Bookinger</h3>
          <div v-if="loading">Indlæser...</div>
          <div v-else>
            <div v-if="upcomingBookings.length === 0">Ingen kommende bookinger</div>
            <ul class="space-y-4">
              <li v-for="booking in upcomingBookings" :key="booking.id" class="border-b border-gray-300 pb-4">
                <p class="text-lg font-semibold">{{ booking.name }}</p>
                <p class="text-gray-700">{{ booking.service }}</p>
                <p class="text-gray-500">{{ booking.date }} {{ booking.time }}</p>
                <p class="text-gray-500 italic">{{ booking.notes }}</p> <!-- Display the notes here -->
                <div class="flex space-x-2">
                  <button @click="approveBooking(booking)" class="bg-green-500 text-white px-4 py-2 rounded-full">Godkend</button>
                  <button @click="deleteBooking(booking.id, booking)" class="bg-red-500 text-white px-4 py-2 rounded-full">Slet</button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Customer Information -->
        <div class="bg-white bg-opacity-50 backdrop-filter backdrop-blur-3xl p-6 rounded-3xl shadow-2xl text-gray-900 col-span-1">
          <h3 class="text-2xl font-semibold mb-4">Kundeoplysninger</h3>
          <div v-if="loading">Indlæser...</div>
          <div v-else>
            <div v-if="customerInfo.length === 0">Ingen kundeoplysninger</div>
            <ul class="space-y-4">
              <li v-for="customer in customerInfo" :key="customer.email" class="border-b border-gray-300 pb-4">
                <p class="text-lg font-semibold">{{ customer.name }}</p>
                <p class="text-gray-700">{{ customer.phone }}</p>
                <p class="text-gray-500">{{ customer.email }}</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- Monthly Earnings -->
        <div class="bg-white bg-opacity-50 backdrop-filter backdrop-blur-3xl p-6 rounded-3xl shadow-2xl text-gray-900 col-span-1 md:col-span-2 lg:col-span-3">
          <h3 class="text-2xl font-semibold mb-4">Månedlige Indtægter</h3>
          <div v-if="loading">Indlæser...</div>
          <div v-else>
            <p class="text-lg">{{ monthlyEarnings }} kr</p>
          </div>
        </div>

        <!-- All Bookings Over 12 Months -->
        <div class="bg-white bg-opacity-50 backdrop-filter backdrop-blur-3xl p-6 rounded-3xl shadow-2xl text-gray-900 col-span-1 md:col-span-2 lg:col-span-3">
          <h3 class="text-2xl font-semibold mb-4">Alle Bookings Over 12 Måneder</h3>
          <div v-if="loading">Indlæser...</div>
          <div v-else>
            <div v-if="allBookings.length === 0">Ingen bookinger</div>
            <ul class="space-y-4">
              <li v-for="booking in allBookings" :key="booking.id" class="border-b border-gray-300 pb-4">
                <p class="text-lg font-semibold">{{ booking.date }}</p>
                <p class="text-gray-700">{{ booking.name }}</p>
                <p class="text-gray-500">{{ booking.service }}</p>
                <p class="text-gray-500 italic">{{ booking.notes }}</p> <!-- Display the notes here -->
                <div class="flex space-x-2">
                  <button @click="approveBooking(booking)" class="bg-green-500 text-white px-4 py-2 rounded-full">Godkend</button>
                  <button @click="deleteBooking(booking.id, booking)" class="bg-red-500 text-white px-4 py-2 rounded-full">Slet</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '../components/BaseLayout.vue';
import { db } from '../firebase';
import { collection, getDocs, query, where, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import emailjs from '@emailjs/browser';

export default {
  metaInfo() {
    return {
      title: 'Salon Jobs - Freshcuts',
      meta: [
        { name: 'description', content: 'Find de nyeste jobopslag for salonarbejde her.' }
      ]
    };
  },
  name: 'DashboardPage',
  components: {
    BaseLayout,
  },
  data() {
    return {
      loading: true,
      todayBookings: [],
      upcomingBookings: [],
      customerInfo: [],
      monthlyEarnings: 0,
      allBookings: [],
      salonName: '',
    };
  },
  async created() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      await this.fetchSalonName(user.uid);
      await this.fetchBookings(user.uid);
    }
  },
  methods: {
    async fetchSalonName(userId) {
      const userDoc = doc(db, 'users', userId);
      const userSnap = await getDoc(userDoc);
      if (userSnap.exists()) {
        this.salonName = userSnap.data().name;
      } else {
        console.error("No such document!");
      }
    },
    async fetchBookings(userId) {
      const today = new Date().toISOString().split('T')[0];
      const bookingsRef = collection(db, 'bookings');
      const q = query(bookingsRef, where('salonId', '==', userId));
      const bookingsSnapshot = await getDocs(q);
      const bookings = bookingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      this.todayBookings = bookings.filter(booking => booking.date === today);
      this.upcomingBookings = bookings.filter(booking => booking.date > today);
      this.customerInfo = bookings.map(booking => ({
        name: booking.name,
        phone: booking.phone,
        email: booking.email,
      }));
      this.allBookings = bookings;
      this.monthlyEarnings = bookings.reduce((total, booking) => total + parseInt(booking.price || 0), 0);

      this.loading = false;
    },
    async sendEmail(to, toName, service, date, time, templateId) {
      try {
        const templateParams = {
          to_name: toName,
          service: service,
          date: date,
          time: time,
          salon_name: this.salonName,
          to_email: to,
        };

        await emailjs.send('service_ehb98xm', templateId, templateParams, 'f8drQdDrH5AfEidz-');
        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    },
    async approveBooking(booking) {
      try {
        const bookingRef = doc(db, 'bookings', booking.id);
        await updateDoc(bookingRef, {
          status: 'approved'
        });
        await this.sendEmail(booking.email, booking.name, booking.service, booking.date, booking.time, 'template_9tnba2y');
        alert('Booking godkendt');
        await this.fetchBookings(getAuth().currentUser.uid);  // Refresh bookings list
      } catch (error) {
        console.error("Error approving booking: ", error);
      }
    },
    async deleteBooking(bookingId, booking) {
      try {
        const bookingRef = doc(db, 'bookings', bookingId);
        await deleteDoc(bookingRef);
        await this.sendEmail(booking.email, booking.name, booking.service, booking.date, booking.time, 'template_k881syh');
        alert('Booking slettet');
        await this.fetchBookings(getAuth().currentUser.uid);  // Refresh bookings list
      } catch (error) {
        console.error("Error deleting booking: ", error);
      }
    }
  },
};
</script>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.shadow-2xl {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
}

.bg-opacity-60 {
  background-color: rgba(255, 255, 255, 0.6);
}

.backdrop-filter {
  backdrop-filter: blur(25px);
}
</style>
