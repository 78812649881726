<template>
    <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div class="bg-white p-6 rounded-lg shadow-lg max-w-md text-center">
        <h2 class="text-xl font-bold mb-4">Få flere kunder!</h2>
        <p class="mb-4">Over 1000 kunder leder efter en god frisør eller neglesalon.</p>
        <p class="font-semibold mb-6">Klik på "Medlemskab" og tilmeld dig nu!</p>
        <button @click="closePopup" class="bg-blue-500 text-white px-4 py-2 rounded-lg">Luk</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showPopup: true // Display popup by default
      };
    },
    methods: {
      closePopup() {
        this.showPopup = false;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Basic styling for the popup */
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
  }
  </style>
  