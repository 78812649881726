<template>
  <div class="min-h-screen flex flex-col bg-gray-100 text-gray-900 font-sans relative z-10">
    <header class="bg-white bg-opacity-60 backdrop-filter backdrop-blur-3xl shadow-2xl rounded-b-3xl z-20 relative">
      <div class="container mx-auto flex justify-between items-center p-6 relative">
        <h1 class="text-3xl font-semibold text-black">Freshcuts</h1>
        <button @click="toggleMenu" class="lg:hidden block text-gray-700 focus:outline-none z-30">
          <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
        <nav :class="{'hidden': !isMenuOpen, 'block': isMenuOpen}" class="lg:flex lg:items-center lg:space-x-4 w-full lg:w-auto absolute lg:relative top-full left-0 lg:top-0 lg:left-0 bg-white lg:bg-transparent z-20 lg:z-auto p-6 lg:p-0 shadow-lg lg:shadow-none rounded-b-3xl lg:rounded-none">
          <router-link @click="toggleMenu" to="/" class="nav-link">Forside</router-link>
          <router-link @click="toggleMenu" to="/booking" class="nav-link">Mine Bookinger</router-link>
          <router-link @click="toggleMenu" to="/hairdressers" class="nav-link">Vores Frisører</router-link>
          <router-link @click="toggleMenu" to="/contact" class="nav-link">Kontakt os</router-link>
          <router-link @click="toggleMenu" to="/salon-jobs" class="nav-link">Salon Jobs</router-link>
          <router-link @click="toggleMenu" to="/dashboard" class="nav-link" v-if="isAuthenticated">Dashboard</router-link>
          <router-link @click="toggleMenu" v-if="!isAuthenticated" to="/login" class="nav-link">Login</router-link>
          <router-link @click="toggleMenu" v-if="!isAuthenticated" to="/partner" class="nav-link-member">Medlemskab</router-link>
          <div v-if="isAuthenticated" class="flex flex-col lg:flex-row items-center space-x-4">
            <span class="text-black">Velkommen, {{ user.email }}</span>
            <button @click="logout" class="nav-button bg-red-500 hover:bg-red-600 text-white">Logout</button>
          </div>
        </nav>
      </div>
    </header>
    <main class="flex-grow container mx-auto p-6 z-10 relative">
      <slot></slot>
    </main>
    <footer class="bg-white text-gray-600 bg-opacity-60 backdrop-filter backdrop-blur-3xl p-4 text-center border-t border-gray-300 rounded-t-3xl shadow-2xl z-20 relative">
      <p>&copy; 2024 Freshcuts. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { auth } from '../firebase';

export default {
  name: 'BaseLayout',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getUser']),
    user() {
      return this.getUser;
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    logout() {
      auth.signOut().then(() => {
        this.$router.push('/');
      });
    },
  },
};
</script>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.rounded-full { border-radius: 9999px; }
.rounded-3xl { border-radius: 1.5rem; }
.shadow-2xl { box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1); }
.shadow-lg { box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); }
.bg-opacity-60 { background-opacity: 0.6; }
.backdrop-filter { backdrop-filter: blur(25px); }
.backdrop-blur-3xl { backdrop-filter: blur(30px); }

.nav-link {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  display: block;
  padding: 10px 20px;
  margin: 5px 0;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  text-align: center;
}

.nav-link:hover {
  background-color: #f3f3f3;
  transform: scale(1.05);
}

.nav-link-member {
  background-color: #000000;
  color: #ffffff;
  border: none;
  display: block;
  padding: 10px 20px;
  margin: 5px 0;
  font-weight: 500;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  text-align: center;
}

.nav-link-member:hover {
  background-color: #333333;
  transform: scale(1.05);
}

.nav-button {
  padding: 10px 20px;
  margin: 5px 0;
  font-weight: 500;
  border-radius: 9999px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #fff;
  text-decoration: none;
}

.nav-button:hover {
  transform: scale(1.05);
}
</style>