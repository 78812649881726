<template>
  <BaseLayout>
    <PopupComponent />
    <div class="space-y-8">
      <!-- Section 1: Find og Book Frisør Nemt -->
      <section class="text-center space-y-4">
        <h2 class="text-4xl md:text-5xl font-semibold text-black">Find og Book Frisør Nemt</h2>
        <h3 class="shine-text text-lg font-semibold text-black">1000+ kunder leder efter frisør på freshcuts.dk</h3>

        <div class="flex flex-wrap justify-center items-center space-x-2 relative">
          <input v-model="address" type="text" placeholder="Indtast by + postnummer" @input="fetchAddressSuggestions"
            class="p-2 border border-gray-300 rounded-lg w-full md:w-1/2 text-gray-900 relative z-20" />
          <button @click="searchSalons"
            class="bg-blue-500 text-white hover:text-white px-3 py-2 rounded-lg transition duration-300 z-20 mt-2 md:mt-0">Søg</button>
          <button @click="getUserLocation"
            class="bg-gray-800 text-white hover:text-white px-3 py-2 rounded-lg transition duration-300 z-20 mt-2 md:mt-0">Nærmeste</button>

          <!-- Autocomplete dropdown -->
          <ul v-if="addressSuggestions.length" class="autocomplete-list">
            <li v-for="(suggestion, index) in addressSuggestions" :key="index" @click="selectSuggestion(suggestion)"
              class="p-2 cursor-pointer hover:bg-gray-200">
              {{ suggestion.tekst }}
            </li>
          </ul>
        </div>

        <!-- Knapper til valg af service type -->
        <div class="flex flex-wrap justify-center items-center mb-4 space-x-2 md:space-x-4">
          <button
            v-for="(type, index) in serviceTypes"
            :key="index"
            :class="['p-2 border rounded-lg text-gray-900 flex-shrink-0', { 'bg-blue-500 text-white': selectedServiceType === type.value }]"
            @click="selectServiceType(type.value)"
          >
            {{ type.icon }} {{ type.label }}
          </button>
        </div>

        <div class="flex flex-col-reverse lg:flex-row mt-8 space-y-4 lg:space-y-0 lg:space-x-4">
          <div class="lg:w-1/3 space-y-4">
            <h3 class="text-2xl font-bold mb-2">Frisører nær dig</h3>
            <ul class="space-y-4">
              <li v-for="(salon, index) in filteredSalons" :key="salon.id"
                  :class="['p-6 bg-black bg-opacity-90 rounded-xl text-white shadow-lg', { 'shimmer-effect': index === 0 }]">

                <div class="flex items-center justify-between mb-4">
                  <div class="flex items-center space-x-4">
                    <img :src="getSalonImage(salon.serviceType)" class="w-20 md:w-28 h-20 md:h-28 object-cover rounded-lg">
                    <div>
                      <h3 class="text-xl md:text-2xl font-semibold text-white">{{ salon.name }}</h3>
                      <p class="text-sm text-gray-400">{{ salon.location }}</p>
                    </div>
                  </div>
                  <p class="text-lg font-semibold text-yellow-400">⭐⭐⭐⭐⭐</p>
                </div>

                <div class="grid grid-cols-2 gap-8">
                  <div class="space-y-1">
                    <p class="flex justify-between">
                      <span class="font-semibold text-white">Afstand:</span>
                      <span class="text-gray-400 text-base font-semibold text-white">
                        {{ salon.distance ? Number(salon.distance).toFixed(2) : 'Ikke tilgængelig' }} km
                      </span>
                    </p>
                    <p class="flex justify-between" v-if="salon.phone">
                      <span class="font-semibold text-white">Telefon:</span>
                      <span class="text-gray-400">{{ salon.phone }}</span>
                    </p>
                    <br>
                    <div v-if="salon.openingHours">
                      <h4 class="text-base font-semibold text-white">Åbningstider:</h4>
                      <ul class="text-sm text-gray-400 space-y-1">
                        <li class="flex justify-between"><span>Hverdage:</span><span>{{ salon.openingHours.weekdays.open
                            }} - {{ salon.openingHours.weekdays.close }}</span></li>
                        <li class="flex justify-between"><span>Lørdag:</span><span>{{ salon.openingHours.saturday.open
                            }} - {{ salon.openingHours.saturday.close }}</span></li>
                        <li class="flex justify-between"><span>Søndag:</span><span>{{ salon.openingHours.sunday ||
                            'Lukket' }}</span></li>
                      </ul>
                    </div>
                  </div>

                  <div v-if="salon.services && salon.services.length > 0">
                    <h4 class="text-base font-semibold text-white">Services:</h4>
                    <ul class="text-sm text-gray-400 space-y-1">
                      <li v-for="service in salon.services" :key="service.name" class="flex justify-between text-white">
                        <span>{{ service.name }}</span>
                        <span>{{ service.price ? service.price + ' DKK' : 'N/A' }}</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="flex justify-between items-center mt-6">
                  <button
                    class="bg-white text-black py-2 px-6 rounded-full text-base font-bold shadow-lg hover:bg-gray-200 transition-all"
                    @click="bookSalon(salon)">Book tid</button>
                  <p class="text-sm text-gray-400">{{ new Date().toLocaleDateString() }}</p>
                </div>
              </li>
            </ul>

            <div v-if="salons.length > displayedSalons.length" class="text-center mt-4">
              <button @click="showMoreSalons"
                class="bg-blue-500 text-white px-4 py-2 rounded-full transition duration-300">Vis flere
                frisører</button>
            </div>
          </div>

          <div class="lg:w-2/3">
            <div class="flex justify-start mb-4">
              <button @click="toggleMap" class="bg-gray-800 text-white px-4 py-2 rounded-lg transition duration-300">
                {{ mapExpanded ? 'Skjul kort' : 'Vis kort' }}
              </button>
            </div>
            <div :class="mapExpanded ? 'block' : 'hidden'" id="map" class="h-80 lg:h-96 rounded-lg shadow-lg"></div>
          </div>
        </div>
      </section>

      <!-- Section 2: About Freshcuts.dk -->
      <section class="text-center space-y-4 bg-gray-100 p-8 rounded-3xl">
        <h2 class="text-3xl font-semibold text-black">Om Freshcuts.dk</h2>
        <p class="text-lg text-gray-700">
          Freshcuts.dk er din foretrukne platform til at finde de bedste frisører i dit område. Vi har samlet de bedste
          frisørsaloner, så du nemt og hurtigt kan finde en frisør, der passer til dine behov. Uanset om du leder efter
          en traditionel klipning, en moderne frisure eller en farvning, kan du finde det hele her.
        </p>
        <p class="text-lg text-gray-700">
          Med over 1200 frisørsaloner på tværs af Danmark og 1000+ tilfredse kunder, er Freshcuts.dk den mest pålidelige
          måde at booke din næste frisøraftale på. Vi værdsætter kvalitet og kundetilfredshed, og vi stræber efter at
          give dig den bedste oplevelse hver gang.
        </p>
      </section>

      <!-- Section 3: Top 3 Salons -->
      <section class="text-center">
        <h2 class="text-2xl font-semibold mb-4 text-black">Top 3 Frisører</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div v-for="salon in top3Salons" :key="salon.id"
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img :src="getSalonImage(salon.serviceType)" alt="" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">{{ salon.name }}</h3>
              <p class="text-sm text-gray-700">{{ salon.location }}</p>
              <p class="text-lg font-bold mt-1 text-blue-500">{{ salon.price }}</p>
              <a :href="salon.website"
                class="bg-blue-500 text-white py-1 px-3 rounded-full transition duration-300 hover:bg-blue-600 text-sm">Besøg
                hjemmeside</a>
            </div>
          </div>
        </div>
      </section>

      <!-- Section 4: Popular Services -->
      <section class="text-center">
        <h2 class="text-2xl font-semibold mb-4 text-black">Populære Services</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1514336937476-a5b961020a5c?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Fade Haircut" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">Fade Klip</h3>
              <p class="text-sm text-gray-700">En populær herrefrisure, hvor håret gradvist bliver kortere mod nakken og
                siderne, hvilket giver et rent og moderne look.</p>
              <p class="text-lg font-bold mt-1 text-blue-500">DKK 199</p>
            </div>
          </div>
          <div
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1456327102063-fb5054efe647?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Men's Haircut" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">Herreklip</h3>
              <p class="text-sm text-gray-700">En klassisk herreklipning, der tilpasser sig dine ønsker for et stilfuldt
                og friskt udseende.</p>
              <p class="text-lg font-bold mt-1 text-blue-500">DKK 199</p>
            </div>
          </div>
          <div
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Women's Haircut" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">Dameklip</h3>
              <p class="text-sm text-gray-700">En professionel klipning, der fremhæver dit ansigt og skaber et elegant
                udtryk.</p>
              <p class="text-lg font-bold mt-1 text-blue-500">DKK 299</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Section 5: New Salons -->
      <section class="text-center">
        <h2 class="text-2xl font-semibold mb-4 text-black">Nye Saloner</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div v-for="salon in newSalons" :key="salon.id"
            class="bg-white p-4 rounded-3xl shadow-lg text-gray-900 transition duration-500 ease-in-out transform hover:scale-105 relative overflow-hidden">
            <img :src="getSalonImage(salon.serviceType)" alt="" class="w-full h-32 object-cover rounded-3xl shadow-md">
            <div class="relative p-3 bg-white rounded-b-3xl text-left">
              <h3 class="text-lg font-semibold mb-1">{{ salon.name }}</h3>
              <p class="text-sm text-gray-700">{{ salon.location }}</p>
              <p class="text-lg font-bold mt-1 text-blue-500">{{ salon.price }}</p>
              <a :href="salon.website"
                class="bg-blue-500 text-white py-1 px-3 rounded-full transition duration-300 hover:bg-blue-600 text-sm">Besøg
                hjemmeside</a>
            </div>
          </div>
        </div>
      </section>

      <!-- Section 6: Conclusion -->
      <section class="text-center space-y-4 bg-gray-100 p-8 rounded-3xl">
        <h2 class="text-3xl font-semibold text-black">Afsluttende Bemærkninger</h2>
        <p class="text-lg text-gray-700">
          Hos Freshcuts.dk er vores mål at gøre det nemt og bekvemt for dig at finde og booke en frisør, der passer til
          dine behov. Vi værdsætter kvalitet og stræber efter at give dig den bedste service hver gang. Tak fordi du
          vælger Freshcuts.dk – vi ser frem til at hjælpe dig med din næste frisøraftale!
        </p>
      </section>
    </div>

    <!-- Modal for Rutevejledning -->
    <div v-if="showModal" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen">
        <div class="bg-white p-6 rounded-lg shadow-lg w-96">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-xl font-semibold">Rutevejledning</h3>
            <button @click="closeModal" class="text-gray-500 hover:text-gray-700">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div id="routeMap" class="h-64"></div>
          <p class="text-gray-600 mt-4">Afstand: {{ modalDistance }} km</p>
          <p class="text-gray-600">Bil tid: {{ modalTime }} min</p>
        </div>
      </div>
    </div>

    <!-- Booking Modal -->
    <BookingModal v-if="selectedSalon" :salon="selectedSalon" @close="closeModal"
      @booking-success="handleBookingSuccess" />
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine'; // Import the routing library
import BaseLayout from '../components/BaseLayout.vue';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import menSalonImage from '../assets/men-salon.jpg';
import womenSalonImage from '../assets/women-salon.jpg';
import defaultSalonImage from '../assets/default-salon.jpg';
import barberIcon from '../assets/barber-pole.png';
import mapPinIcon from '../assets/map-pin.png';
import BookingModal from '../components/BookingModal.vue';
import PopupComponent from '../components/PopupComponent.vue';


const customIcon = L.icon({
  iconUrl: barberIcon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38]
});

export default {
  name: 'HomePage',
  metaInfo() {
    return {
      title: 'Book de Bedste Frisører og Neglesaloner i København | Fade, Herreklip, Gele- og Akrylnegle',
      meta: [
        {
          name: 'description',
          content: 'Find og book tid hos de frisører og neglesaloner i København. Specialister i Fade, Herreklip, Dameklip, Gele- og Akrylnegle. Enkel booking, eksklusiv service'
        }
      ]
    };
  },
  components: {
    BaseLayout,
    BookingModal,
    PopupComponent,
  },
  data() {
    return {
      selectedServiceType: '', // Ny data property
      serviceTypes: [
        { value: 'fade', label: 'Fade Klipning', icon: '💈' },
        { value: 'negle', label: 'Neglebehandling', icon: '💅' },
        { value: 'Men', label: 'Mande Frisør', icon: '👨' },
        { value: 'Women', label: 'Kvinde Frisør', icon: '👩' },
        { value: 'Default', label: 'Alle', icon: '✂️' },  // Ændret label fra "Normal Frisør" til "Alle"
        ],
      selectedSalon: null,
      address: '',
      addressSuggestions: [],
      salons: [],
      top3Salons: [],
      popularServices: [],
      newSalons: [],
      userLocation: null,
      loading: true,
      mapExpanded: true,
      itemsToShow: 5,
      routingControl: null,
      showModal: false,
      modalDistance: 0,
      modalTime: 0,
      map: null,
      routeMap: null, // New route map for the modal
    };
  },
  computed: {
    sortedSalons() {
      return this.salons.slice().sort((a, b) => a.distance - b.distance);
    },
    displayedSalons() {
      return this.sortedSalons.slice(0, this.itemsToShow);
    },
    filteredSalons() { 
    if (this.selectedServiceType && this.selectedServiceType !== 'Default') {
      return this.displayedSalons.filter(salon => salon.serviceType === this.selectedServiceType);
    }
    return this.displayedSalons; // Hvis "Alle" er valgt, vises alle saloner
  }
  },
  async created() {
    await this.fetchSalons();
    await this.fetchTop3Salons();
    await this.fetchNewSalons();
    this.getUserLocation();
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
    if (this.routingControl) {
      this.routingControl.getPlan().setWaypoints([]);
      this.routingControl = null;
    }
    if (this.routeMap) {
      this.routeMap.remove();
      this.routeMap = null;
    }
  },
  methods: {
    selectServiceType(serviceType) {
      this.selectedServiceType = serviceType;
    },
    bookSalon(salon) {
      this.selectedSalon = salon;
    },
    closeModal() {
      this.selectedSalon = null;
      if (this.routeMap) {
        this.routeMap.remove();
        this.routeMap = null;
      }
    },
    handleBookingSuccess() {
      alert('Booking successful!');
      this.closeModal();
    },
    async fetchSalons() {
      const salonCollection = collection(db, 'salons');
      const salonSnapshot = await getDocs(salonCollection);
      const salonList = salonSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      this.salons = salonList;
      this.calculateDistances();
      this.loading = false;
    },
    async fetchTop3Salons() {
      this.top3Salons = this.salons.slice(0, 3);
    },
    async fetchNewSalons() {
      this.newSalons = this.salons.slice(-3);
    },
    getSalonImage(serviceType) {
      if (serviceType === 'Men') {
        return menSalonImage;
      } else if (serviceType === 'Women') {
        return womenSalonImage;
      } else {
        return defaultSalonImage;
      }
    },
    async getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.handlePositionSuccess, this.handlePositionError);
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    handlePositionSuccess(position) {
      const { latitude, longitude } = position.coords;
      this.userLocation = { lat: latitude, lon: longitude };
      this.initializeMap();
      this.addUserLocationPin();
      this.calculateDistances();
    },
    handlePositionError(error) {
      console.error("Error getting user location:", error);
      this.loading = false;
    },
    async searchSalons() {
      if (!this.address) return;
      try {
        const response = await axios.get('https://nominatim.openstreetmap.org/search', {
          params: {
            q: this.address,
            format: 'json',
            addressdetails: 1,
            limit: 1
          }
        });
        const coordinates = response.data[0];
        const lat = parseFloat(coordinates.lat);
        const lon = parseFloat(coordinates.lon);
        this.userLocation = { lat, lon };
        this.initializeMap();
        this.addUserLocationPin();
        this.calculateDistances();
      } catch (error) {
        console.error("Error fetching geocoding data: ", error);
      }
    },
    async fetchAddressSuggestions() {
      if (!this.address) {
        this.addressSuggestions = [];
        return;
      }

      try {
        const response = await axios.get('https://api.dataforsyningen.dk/autocomplete', {
          params: {
            q: this.address,
            per_side: 5
          }
        });
        this.addressSuggestions = response.data;
      } catch (error) {
        console.error("Error fetching address suggestions: ", error);
      }
    },
    selectSuggestion(suggestion) {
      this.address = suggestion.tekst;
      this.addressSuggestions = [];
      this.searchSalons();
    },
    calculateDistances() {
      if (!this.userLocation) return;
      this.salons = this.salons.map(salon => {
        const distance = this.calculateDistance(
          this.userLocation.lat,
          this.userLocation.lon,
          parseFloat(salon.lat),
          parseFloat(salon.lon)
        );
        return { ...salon, distance };
      });
      this.salons.sort((a, b) => a.distance - b.distance);
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Earth radius in km
      const dLat = this.deg2rad(lat2 - lat1);
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      return distance;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    initializeMap() {
      if (this.map) {
        this.map.setView([this.userLocation.lat, this.userLocation.lon], 13);
        return;
      }

      this.map = L.map('map', {
        zoomAnimation: false, // Deaktiver zoom-animation
      }).setView([this.userLocation.lat, this.userLocation.lon], 13);

      L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://carto.com/attributions">CARTO</a>',
      }).addTo(this.map);

      this.salons.forEach(salon => {
        if (salon.lat && salon.lon) {
          const marker = L.marker([salon.lat, salon.lon], { icon: customIcon }).addTo(this.map);

          marker.on('click', () => {
            const servicesList = salon.services?.slice(0, 3).map(service => `<li>${service.name} - ${service.price || 'N/A'} DKK</li>`).join('') || '';
            const popupContent = `
                  <b>${salon.name}</b><br>
                  ${salon.location}<br>
                  Afstand: ${salon.distance ? salon.distance.toFixed(2) : 'N/A'} km<br>
                  Services:<ul>${servicesList}</ul>
                  <button @click.prevent="showRouteModal(${salon.lat}, ${salon.lon})" class="bg-blue-500 text-white mt-2 px-2 py-1 rounded">Rutevejledning</button>
                `;
            marker.bindPopup(popupContent).openPopup();
          });
        }
      });
    },

    addUserLocationPin() {
      if (this.userLocation) {
        const userIcon = L.icon({
          iconUrl: mapPinIcon,
          iconSize: [50, 50],
          iconAnchor: [25, 50],
          popupAnchor: [0, -50]
        });

        L.marker([this.userLocation.lat, this.userLocation.lon], { icon: userIcon })
          .addTo(this.map)
          .bindPopup(`<b>Din placering</b>`);
      }
    },
    drawRouteToSalon(salonLat, salonLon) {
      if (!this.map) {
        console.error("Map is not initialized.");
        return;
      }

      if (this.routingControl) {
        this.map.removeControl(this.routingControl);
      }

      this.routingControl = L.Routing.control({
        waypoints: [
          L.latLng(this.userLocation.lat, this.userLocation.lon),
          L.latLng(salonLat, salonLon)
        ],
        routeWhileDragging: true,
        showAlternatives: false,
        addWaypoints: false,
        draggableWaypoints: false,
        createMarker: () => null,
        lineOptions: {
          styles: [{ color: '#1c54b2', weight: 5 }]
        }
      }).addTo(this.map);

      this.routingControl.on('routesfound', (e) => {
        const distance = e.routes[0].summary.totalDistance / 1000; // in km
        const time = Math.round(e.routes[0].summary.totalTime / 60); // in minutes

        this.modalDistance = distance.toFixed(2);
        this.modalTime = time;
        this.showModal = true;

        this.$nextTick(() => {
          if (this.routeMap) {
            this.routeMap.remove();
          }

          this.routeMap = L.map('routeMap').setView([this.userLocation.lat, this.userLocation.lon], 13);

          L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors & <a href="https://carto.com/attributions">CARTO</a>',
          }).addTo(this.routeMap);

L.Routing.control({
  waypoints: [
    L.latLng(this.userLocation.lat, this.userLocation.lon),
    L.latLng(salonLat, salonLon)
  ],
  routeWhileDragging: true,
  showAlternatives: false,
  addWaypoints: false,
  draggableWaypoints: false,
  createMarker: () => null,
  lineOptions: {
    styles: [{ color: '#1c54b2', weight: 5 }]
  }
}).addTo(this.routeMap);
});
});
},
showRouteModal(salonLat, salonLon) {
this.drawRouteToSalon(salonLat, salonLon);
},
toggleMap() {
this.mapExpanded = !this.mapExpanded;
this.$nextTick(() => {
if (this.mapExpanded && this.map) {
this.map.invalidateSize();
}
});
},
showMoreSalons() {
this.itemsToShow += 5;
},
}
}
</script>

<style>
.fade-slide-enter-active,
.fade-slide-leave-active {
transition: all 0.5s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
opacity: 0;
transform: translateX(10px);
}

.bg-opacity-80 {
background-color: rgba(255, 255, 255, 0.8);
}

.backdrop-filter {
backdrop-filter: blur(20px);
}

.shadow-lg {
box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-3xl {
border-radius: 1.5rem;
}

.transform {
transform: scale(1);
}

.hover\:scale-105:hover {
transform: scale(1.05);
}

.transition {
transition: all 0.5s ease;
}

.relative {
position: relative;
}

.overflow-hidden {
overflow: hidden;
}

.object-cover {
object-fit: cover;
}

.z-10 {
z-index: 10;
}

.rounded-t-3xl {
border-top-left-radius: 1.5rem;
border-top-right-radius: 1.5rem;
}

.shine-text {
display: inline-block;
font-weight: bold;
position: relative;
color: #000;
animation: shine 2s infinite;
}

@keyframes shine {
0% {
text-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.5);
}

100% {
text-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 1), 0 0 40px rgba(255, 255, 255, 1);
}
}

#map,
#routeMap {
width: 100%;
height: 575px;
}

.fixed {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
}

.bg-white {
background-color: #fff;
}

.autocomplete-list {
position: absolute;
top: 100%;
left: 0;
right: 0;
z-index: 20;
background-color: white;
border: 1px solid #ccc;
border-radius: 0.5rem;
margin-top: 0.5rem;
max-height: 200px;
overflow-y: auto;
}

.autocomplete-list li {
padding: 8px;
cursor: pointer;
transition: background-color 0.2s ease;
}

.autocomplete-list li:hover {
background-color: #f0f0f0;
}

/* Tilføj stil til modal baggrunden */
.modal-background {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}

.shimmer-effect {
  border-radius: 1.5rem;
  box-shadow: 0 0 0.5px rgba(255, 0, 0, 0.05),  /* Meget subtil neon-rød */
              0 0 0.5px rgba(0, 0, 255, 0.05),  /* Meget subtil neon-blå */
              0 0 0.5px rgba(148, 0, 211, 0.05); /* Meget subtil neon-lilla */
  animation: shimmer 4s infinite linear;
}

@keyframes shimmer {
  0% {
    box-shadow: 0 0 0.5px rgba(255, 0, 0, 0.05), 
                0 0 0.5px rgba(0, 0, 255, 0.05), 
                0 0 0.5px rgba(148, 0, 211, 0.05);
  }
  50% {
    box-shadow: 0 0 0.5px rgba(148, 0, 211, 0.05), 
                0 0 0.5px rgba(255, 0, 0, 0.05), 
                0 0 0.5px rgba(0, 0, 255, 0.05);
  }
  100% {
    box-shadow: 0 0 0.5px rgba(255, 0, 0, 0.05), 
                0 0 0.5px rgba(0, 0, 255, 0.05), 
                0 0 0.5px rgba(148, 0, 211, 0.05);
  }
}



</style>
