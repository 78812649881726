// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import BookingPage from '../pages/BookingPage.vue';
import ContactPage from '../pages/ContactPage.vue';
import DashboardPage from '../pages/DashboardPage.vue';
import HairdressersPage from '../pages/HairdressersPage.vue';
import SalonJobs from '../pages/SalonJobs.vue';
import LoginPage from '../pages/LoginPage.vue';
import RegisterPage from '../pages/RegisterPage.vue';
import store from '../store';

const routes = [
  { path: '/', component: HomePage },
  { path: '/booking', component: BookingPage },
  { path: '/contact', component: ContactPage },
  { path: '/dashboard', component: DashboardPage, meta: { requiresAuth: true } },
  { path: '/hairdressers', component: HairdressersPage },
  { path: '/salon-jobs', component: SalonJobs },
  { path: '/login', component: LoginPage },
  { path: '/partner', component: RegisterPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

/*
"Det er en svær situation, men vi skal nok komme igennem det. Lad os tage det skridt for skridt, og jeg er her for at hjælpe dig med at få styr på det hele."

"Det vigtigste lige nu er at få ro på og få styr på, hvad der skal ske fremadrettet. Jeg er her for dig, og vi finder ud af det sammen."
*/