<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded-3xl shadow-2xl text-gray-900 w-full max-w-lg">
      <h2 class="text-4xl font-semibold mb-2 text-center">Booking</h2>
      <p class="text-lg text-center text-gray-600 mb-6">{{ salon.name }}</p>

      <!-- Step 1: Service Selection -->
      <div v-if="step === 1" class="space-y-6">
        <div>
          <label for="service" class="block text-lg">Vælg Service</label>
          <select id="service" v-model="selectedService" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            <option v-for="service in salon.services" :key="service.name" :value="service.name">
              {{ service.name }} - {{ service.price }} DKK
            </option>
          </select>
        </div>
        <div>
          <label for="notes" class="block text-lg">Specifikke ønsker eller kommentarer</label>
          <textarea id="notes" v-model="customerNotes" class="w-full p-3 border border-gray-300 rounded-full text-gray-900"></textarea>
        </div>
        <button @click="nextStep" class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition duration-300">Næste</button>
      </div>

      <!-- Step 2: Date and Time Selection -->
      <div v-if="step === 2" class="space-y-6">
        <div>
          <label for="date" class="block text-lg">Dato</label>
          <input type="date" id="date" v-model="date" @change="fetchAvailableTimes" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
        </div>
        <div>
          <label for="time" class="block text-lg">Tid</label>
          <div class="space-y-2">
            <div v-for="timeSlot in availableTimes" :key="timeSlot.time" class="flex items-center">
              <input
                type="radio"
                :id="timeSlot.time"
                :value="timeSlot.time"
                v-model="time"
                :disabled="!timeSlot.available"
                class="mr-2"
              />
              <label :for="timeSlot.time" :class="[timeSlot.available ? 'text-green-600' : 'text-red-600']">
                {{ timeSlot.time }}
              </label>
            </div>
          </div>
        </div>
        <button @click="nextStep" :disabled="!time" class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition duration-300">Næste</button>
      </div>

      <!-- Step 3: Contact Information -->
      <div v-if="step === 3" class="space-y-6">
        <div>
          <label for="name" class="block text-lg">Navn</label>
          <input type="text" id="name" v-model="name" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
        </div>
        <div>
          <label for="phone" class="block text-lg">Telefon</label>
          <input type="tel" id="phone" v-model="phone" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
        </div>
        <div>
          <label for="email" class="block text-lg">Email</label>
          <input type="email" id="email" v-model="email" class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
        </div>
        <button @click="nextStep" class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition duration-300">Næste</button>
      </div>

      <!-- Step 4: Confirmation -->
      <div v-if="step === 4" class="space-y-6">
        <h3 class="text-xl font-semibold">Bekræft Aftale</h3>
        <p><strong>Service:</strong> {{ selectedService }}</p>
        <p><strong>Dato:</strong> {{ date }}</p>
        <p><strong>Tid:</strong> {{ time }}</p>
        <p><strong>Navn:</strong> {{ name }}</p>
        <p><strong>Telefon:</strong> {{ phone }}</p>
        <p><strong>Email:</strong> {{ email }}</p>
        <p><strong>Ønsker/Kommentarer:</strong> {{ customerNotes }}</p>
        <button @click="book" class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition duration-300">Bekræft</button>
        <button @click="prevStep" class="bg-gray-500 hover:bg-gray-600 text-white w-full py-3 rounded-full transition duration-300">Tilbage</button>
      </div>

      <!-- Close Button -->
      <button @click="$emit('close')" class="mt-4 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-full transition duration-300">Luk</button>
    </div>
  </div>
</template>

<script>
import { db } from '../firebase';
import { addDoc, collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import emailjs from '@emailjs/browser';

export default {
  name: 'BookingModal',
  props: {
    salon: Object,
  },
  data() {
    return {
      step: 1,
      selectedService: '',
      name: '',
      phone: '',
      email: '',
      date: '',
      time: '',
      customerNotes: '',  // Add this line to bind the new text field
      availableTimes: [],
    };
  },
  methods: {
    nextStep() {
      if (this.step < 4) {
        this.step++;
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    async fetchAvailableTimes() {
      if (!this.date) return;

      const bookedTimesQuery = query(
        collection(db, 'bookings'),
        where('salonId', '==', this.salon.id),
        where('date', '==', this.date)
      );
      const bookedTimesSnapshot = await getDocs(bookedTimesQuery);
      const bookedTimes = bookedTimesSnapshot.docs.map(doc => doc.data().time);

      this.availableTimes = this.generateTimeSlots().map((timeSlot) => ({
        time: timeSlot,
        available: !bookedTimes.includes(timeSlot),
      }));
    },
    generateTimeSlots() {
      const times = [];
      let currentTime = new Date(`${this.date}T09:00:00`); // Salon opens at 9:00 AM
      const closingTime = new Date(`${this.date}T17:00:00`); // Salon closes at 5:00 PM

      while (currentTime < closingTime) {
        times.push(currentTime.toTimeString().slice(0, 5)); // HH:mm format
        currentTime = new Date(currentTime.getTime() + 30 * 60000); // 30 minutes later
      }

      return times;
    },
    async book() {
      try {
        await addDoc(collection(db, 'bookings'), {
          salonId: this.salon.id,
          service: this.selectedService,
          name: this.name,
          phone: this.phone,
          email: this.email,
          date: this.date,
          time: this.time,
          notes: this.customerNotes,  // Store the customer notes in the booking
          status: 'pending',
        });

        const salonOwnerRef = doc(db, 'users', this.salon.id);
        const salonOwnerDoc = await getDoc(salonOwnerRef);
        if (salonOwnerDoc.exists()) {
          const salonOwnerEmail = salonOwnerDoc.data().email;
          await this.sendEmail(
            salonOwnerEmail,
            this.salon.name,
            this.selectedService,
            this.date,
            this.time,
            this.name,
            this.phone
          );
        }

        this.$emit('bookingSuccess');
      } catch (error) {
        console.error("Error creating booking: ", error);
      }
    },
    async sendEmail(to, salonName, serviceName, date, time, customerName, customerPhone) {
      try {
        const templateParams = {
          to_name: salonName,
          service: serviceName,
          date: date,
          time: time,
          customer_name: customerName,
          customer_phone: customerPhone,
          to_email: to,
        };

        await emailjs.send('service_ehb98xm', 'template_9tnba2y', templateParams, 'f8drQdDrH5AfEidz-');
        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    },
  },
};
</script>

<style scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
