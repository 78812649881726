<template>
  <BaseLayout>
    <div class="flex justify-center items-center min-h-screen">
      <div
        class="bg-white bg-opacity-60 backdrop-filter backdrop-blur-3xl p-6 rounded-3xl shadow-2xl text-gray-900 w-full max-w-5xl">
        <h2 class="text-4xl font-semibold mb-6 text-center">Medlemskab</h2>
        <div class="flex space-x-6">
          <div class="bg-white bg-opacity-50 backdrop-filter backdrop-blur-3xl p-4 rounded-3xl shadow-2xl w-1/2">
            <h3 class="text-2xl font-semibold mb-4">🚀 Tiltræk Flere Kunder Med Vores Program 📈</h3>
            <h4 class="text-lg font-semibold mb-2">FRESHCUTS PARTNER</h4>
            <p class="text-md mb-2">99,- / md.</p>
            <ul class="text-md space-y-2 list-disc list-inside">
              <li>Få flere kunder med det samme</li>
              <li>Bliv en del af et stærkt fællesskab af professionelle frisører</li>
              <li>Synlighed uden besvær: Bliv set af en konstant strøm af daglige besøgende uden indsats</li>
              <li>Ingen risiko, kun fordele: Bliv partner uden startomkostninger - kun 199,- pr. måned</li>
              <li>Egen profil: Få din dedikerede side til at tiltrække flere kunder</li>
              <li>Stærkt frisørnetværk: Bliv en del af et professionelt fællesskab af frisører</li>
              <li>Gratis promovering: Bliv set af en konstant strøm af daglige besøgende uden indsats</li>
              <li>Ingen binding: Sig op når som helst</li>
            </ul>
            <div class="flex justify-center">
              <button @click="showForm = true"
                class="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-full transition duration-300">
                Bliv Medlem
              </button>
            </div>
            <div class="text-center mt-6">
              <h3 class="text-lg font-semibold mb-4">Trin 2: Betal for Aktivering</h3>
              <p class="text-md mb-4">For at fuldføre din registrering, skal du betale for aktiveringen af din frisørsalon.</p>
              <a href="https://buy.stripe.com/9AQ8yWd9EgMlav628f" target="_blank"
                 class="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-full transition duration-300">
                Betal nu
              </a>
            </div>
          </div>

          <!-- Kommer Snart Sektion -->
          <div class="bg-white bg-opacity-50 backdrop-filter backdrop-blur-3xl p-4 rounded-3xl shadow-2xl w-1/2">
            <h4 class="text-lg font-semibold mb-2">🚧 KOMMER SNART... 🚧</h4>
            <h4 class="text-lg font-semibold mb-2">FRESHCUTS PARTNER - MÅNEDENS FRISØR</h4>
            <p class="text-md mb-2">499,- / md.</p>
            <ul class="text-md space-y-2 list-disc list-inside">
              <li>Bliv Månedens Frisør!</li>
              <li>KOM PÅ FORSIDEN!</li>
              <li>Få flere kunder med det samme</li>
              <li>Bliv en del af et stærkt fællesskab af professionelle frisører</li>
              <li>Synlighed uden besvær: Bliv set af en konstant strøm af daglige besøgende uden indsats</li>
              <li>Egen profil: Få din dedikerede side til at tiltrække flere kunder</li>
              <li>Stærkt frisørnetværk: Bliv en del af et professionelt fællesskab af frisører</li>
              <li>Gratis promovering: Bliv set af en konstant strøm af daglige besøgende uden indsats</li>
              <li>Ingen binding: Sig op når som helst</li>
            </ul>
            <div class="flex justify-center">
              <button @click="showForm = true"
                class="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-3 py-2 rounded-full transition duration-300">
                Bliv Medlem
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Pop-up Registreringsformular -->
      <div v-if="showForm" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center overflow-auto z-50">
        <div class="bg-white p-6 rounded-3xl shadow-2xl text-gray-900 w-full max-w-lg max-h-[80vh] overflow-y-auto">
          <h3 class="text-2xl font-semibold mb-4 text-center">Register</h3>
          <form @submit.prevent="register('partner')" class="space-y-4">

            <!-- Virksomhedstype -->
            <div>
              <label for="businessType" class="block text-lg">Virksomhedstype</label>
              <select id="businessType" v-model="businessType"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
                <option disabled value="">Vælg type</option>
                <option value="salon">💈 Frisør</option>
                <option value="klinik">💅 Klinik</option>
              </select>
            </div>

            <!-- Navn, Email, Password, Beskrivelse, Adresse, Telefon -->
            <div>
              <label for="name" class="block text-lg">Navn</label>
              <input type="text" id="name" v-model="name" placeholder="F.eks. Salon Navn"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="email" class="block text-lg">Email</label>
              <input type="email" id="email" v-model="email" placeholder="F.eks. email@example.com"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="password" class="block text-lg">Password</label>
              <input type="password" id="password" v-model="password" placeholder="Vælg et sikkert kodeord"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="description" class="block text-lg">Beskrivelse</label>
              <textarea id="description" v-model="description" placeholder="Kort beskrivelse af din salon"
                class="w-full p-3 border border-gray-300 rounded-lg text-gray-900" required></textarea>
            </div>
            <div>
              <label for="location" class="block text-lg">Adresse</label>
              <input type="text" id="location" v-model="location" placeholder="F.eks. Nørrebrogade 110, 2200 København"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>
            <div>
              <label for="phone" class="block text-lg">Telefon</label>
              <input type="tel" id="phone" v-model="phone" placeholder="F.eks. +45 35353370"
                class="w-full p-3 border border-gray-300 rounded-full text-gray-900" required>
            </div>

            <!-- Upload billeder af salonen -->
            <div>
              <label for="salonImages" class="block text-lg">Upload billeder af salonen</label>
              <input type="file" id="salonImages" @change="onFileChange" multiple class="w-full p-3 border border-gray-300 rounded-full">
            </div>

            <!-- Åbningstider -->
            <div>
              <label for="weekdays_open" class="block text-lg">Hverdage Åbningstider</label>
              <div class="flex space-x-2">
                <input type="time" id="weekdays_open" v-model="openingHours.weekdays.open"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
                <input type="time" id="weekdays_close" v-model="openingHours.weekdays.close"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
              </div>
            </div>
            <div>
              <label for="saturday_open" class="block text-lg">Lørdag Åbningstider</label>
              <div class="flex space-x-2">
                <input type="time" id="saturday_open" v-model="openingHours.saturday.open"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
                <input type="time" id="saturday_close" v-model="openingHours.saturday.close"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900" required>
              </div>
            </div>
            <div>
              <label for="sunday_open" class="block text-lg">Søndag Åbningstider</label>
              <div class="flex space-x-2">
                <input type="time" id="sunday_open" v-model="openingHours.sunday.open"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900">
                <input type="time" id="sunday_close" v-model="openingHours.sunday.close"
                  class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900">
              </div>
            </div>

            <!-- Services (Universelle og tilføj special services) -->
            <div>
              <label class="block text-lg">Vælg Tjenester</label>
              <div v-for="(service, index) in universalServices" :key="index" class="space-y-2">
                <div class="flex justify-between">
                  <span>{{ service.name }}</span>
                  <input type="number" v-model="service.price" :placeholder="`Standard Pris: ${service.defaultPrice}`"
                    class="w-1/2 p-3 border border-gray-300 rounded-full text-gray-900">
                </div>
              </div>
            </div>

            <button type="button" @click="addService"
              class="bg-green-500 hover:bg-green-600 text-white w-full py-2 rounded-full transition duration-300">
              Tilføj Special Service
            </button>

            <button type="submit"
              class="bg-blue-500 hover:bg-blue-600 text-white w-full py-3 rounded-full transition duration-300">
              Register
            </button>
          </form>

          <button @click="showForm = false"
            class="mt-4 bg-red-500 hover:bg-red-600 text-white w-full py-2 rounded-full transition duration-300">
            Luk
          </button>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "@/firebase"; // Firebase storage import
import BaseLayout from '../components/BaseLayout.vue';
import { getDocs, collection } from "firebase/firestore"; // For fetching universal services

export default {
  name: 'RegisterPage',
  metaInfo() {
    return {
      title: 'Salon Jobs - Freshcuts',
      meta: [
        { name: 'description', content: 'xxx de nyeste jobopslag for salonarbejde her.' }
      ]
    };
  },
  components: {
    BaseLayout,
  },
  data() {
    return {
      email: '',
      password: '',
      name: '',
      description: '',
      location: '',
      phone: '',
      serviceType: '',
      businessType: '',
      openingHours: {
        weekdays: { open: '08:00', close: '18:00' },
        saturday: { open: '08:00', close: '16:00' },
        sunday: { open: '', close: '' },
      },
      services: [], // For custom services
      universalServices: [], // For universal services
      selectedFiles: null, // For salon images
      error: null,
      showForm: false,
    };
  },
  methods: {
    onFileChange(event) {
      this.selectedFiles = event.target.files;
    },
    async uploadImages() {
      const imageUrls = [];
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        const storageRef = ref(storage, `salon_images/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        imageUrls.push(downloadUrl);
      }
      return imageUrls;
    },
    async register(role) {
      try {
        this.error = null;
        const auth = getAuth();
        const db = getFirestore();
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Upload images if any
        const imageUrls = await this.uploadImages();

        // Add user to Firestore
        await setDoc(doc(db, 'users', user.uid), {
          name: this.name,
          email: this.email,
          role: role,
          createdAt: new Date(),
        });

        // Add salon to Firestore
        await setDoc(doc(db, 'salons', user.uid), {
          name: this.name,
          description: this.description,
          location: this.location,
          phone: this.phone,
          serviceType: this.serviceType,
          businessType: this.businessType,
          openingHours: this.openingHours,
          services: this.services,
          universalServices: this.universalServices,
          images: imageUrls, // Save the image URLs here
          createdAt: new Date(),
        });

        this.$router.push('/dashboard');
      } catch (error) {
        console.error("Error registering user: ", error);
        this.error = 'Der opstod en fejl. Prøv igen senere.';
      }
    },
    addService() {
      this.services.push({ name: '', price: '' });
    },
    async fetchUniversalServices() {
      const db = getFirestore();
      const servicesSnapshot = await getDocs(collection(db, "universalServices"));
      this.universalServices = servicesSnapshot.docs.map(doc => doc.data().services).flat();
    }
  },
  async mounted() {
    this.fetchUniversalServices();
  }
};
</script>

<style scoped>
.bg-opacity-60 {
  background-opacity: 0.6;
}

.backdrop-filter {
  backdrop-filter: blur(15px);
}

.backdrop-blur-3xl {
  backdrop-filter: blur(30px);
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.shadow-2xl {
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
}

.list-inside {
  padding-left: 1.25rem;
}
</style>
