// src/store/index.js
import { createStore } from 'vuex';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const store = createStore({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    fetchUser({ commit }) {
      onAuthStateChanged(auth, user => {
        if (user) {
          commit('setUser', user);
        } else {
          commit('setUser', null);
        }
      });
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user;
    },
    getUser(state) {
      return state.user;
    },
  },
});

export default store;
